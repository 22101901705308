import React, { useState, useEffect } from 'react';
import Layout from '@accrosoft-ltd/accropress-theme-inspiration/src/components/layout';
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

import { Link } from 'gatsby';

import SEO from '@accrosoft-ltd/accropress-websites-components/dist/esm/seo';

import BasicSlider from '@accrosoft-ltd/accropress-theme-inspiration/src/components/BasicSlider';

import ExtraHomepageContentTop from '@accrosoft-ltd/accropress-theme-inspiration/src/templates/HomepageComponents/extraHomepageContentTop';
import ExtraHomepageContentBottom from '@accrosoft-ltd/accropress-theme-inspiration/src/templates/HomepageComponents/extraHomepageContentBottom';
import HeroInnerContent from '@accrosoft-ltd/accropress-theme-inspiration/src/templates/HomepageComponents/heroInnerContent';

import buildMediaContent from '@accrosoft-ltd/accropress-websites-components/dist/esm/buildMediaContent';

import loadable from '@loadable/component';

const WigLoader = loadable(() =>
  import('@accrosoft-ltd/accropress-websites-components/dist/esm/wigLoader')
);
const WeducNewsFeed = loadable(() =>
  import('@accrosoft-ltd/accropress-websites-components/dist/esm/weducNewsfeed')
);
const WeducDairyDates = loadable(() =>
  import(
    '@accrosoft-ltd/accropress-websites-components/dist/esm/weducDairyDates'
  )
);
const MapWidget = loadable(() =>
  import('@accrosoft-ltd/accropress-websites-components/dist/esm/mapWidget')
);

var Scroll = require('react-scroll');
var Element = Scroll.Element;
var scroller = Scroll.scroller;

require('es6-promise').polyfill();
var moment = require('moment');

export function NoticeTemplate(props) {
  return (
    <span className="noticesContent">
      {props.data.attachment &&
      props.data.attachment[0] &&
      props.data.attachment[0].external_url ? (
        <a
          href={props.data.attachment[0].external_url}
          title={props.data.attachment[0].title}
          target="_blank"
        >
          <strong className="title">
            {props.data.title.replace(/&nbsp;/g, '').replace(/&pound;/g, '£')}
          </strong>
          <div dangerouslySetInnerHTML={{ __html: props.data.message }}></div>
        </a>
      ) : (
        <>
          <strong className="title">
            {props.data.title.replace(/&nbsp;/g, '').replace(/&pound;/g, '£')}
          </strong>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: props.data.message }}
          ></div>
        </>
      )}
    </span>
  );
}

export default function HomepagePagePage({
  pageContext: {
    integratorData,
    contentMetaData,
    contentDataData,
    navResultData,
    pluginOptions,
  },
  props,
}) {
  const config = pluginOptions;

  const [toggled, setToggled] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const [noticesMainLoaded, setNoticesMainLoaded] = useState(false);
  const [noticesEmergencyLoaded, setNoticesEmergencyLoaded] = useState(false);
  const [newsfeedLoaded, setNewsfeedLoaded] = useState(false);
  const [calendarEventsLoaded, setCalendarEventsLoaded] = useState(false);

  const [noticesBannerExpanded, setNoticesBannerExpanded] = useState(false);

  const [videoPlayerModalTriggered, setVideoPlayerModalTriggered] = useState(
    false
  );

  const [schoolInformationTabActive, setSchoolInformationTabActive] = useState(
    null
  );

  const [logoExpanded, setLogoExpanded] = useState(false);

  const schoolDetailsOnClick = function (e) {
    if (logoExpanded) {
      setLogoExpanded(false);
    } else {
      e.preventDefault();
      setLogoExpanded(true);
    }
  };

  useEffect(() => {
    var isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: function () {
        return (
          navigator.userAgent.match(/IEMobile/i) ||
          navigator.userAgent.match(/WPDesktop/i)
        );
      },
      any: function () {
        return (
          isMobile.Android() ||
          isMobile.BlackBerry() ||
          isMobile.iOS() ||
          isMobile.Opera() ||
          isMobile.Windows()
        );
      },
    };

    var video = document.getElementById('hero_video_player');

    if (!isMobile.any() && video) {
      // get reference to video sources
      var sources = document.getElementsByClassName('video__source');

      // loop through and replace data-src with src
      for (var i = 0; i < sources.length; i++) {
        if (sources[i].getAttribute('data-src')) {
          sources[i].setAttribute('src', sources[i].getAttribute('data-src'));
          sources[i].removeAttribute('data-src'); // use only if you need to remove data-src attribute after setting src
        }
      }

      video.muted = true;

      // listen for canplay event and fade video in
      video.addEventListener('canplay', function () {
        //console.log('video duration information available');
        video.style.transition = 'opacity 2s';
        video.style.opacity = 1;
      });

      // reload video sources
      video.load();
      video.play();
    }
  }, [videoPlayerModalTriggered]);

  const checkContent = (content) => {
    let div = document.createElement('div');
    div.innerHTML = content;

    var image = div.getElementsByTagName('img')[0];

    if (image && !image.getAttribute('src').includes('external')) {
      image.remove();
    }

    return div.outerHTML;
  };

  const toggleNoticesBanner = function () {
    if (noticesBannerExpanded) {
      setNoticesBannerExpanded(false);
    } else {
      setNoticesBannerExpanded(true);
    }
  };

  console.log('integrators data: ');
  console.log(integratorData);

  console.log('content meta data: ');
  console.log(contentMetaData);

  console.log('content data data: ');
  console.log(contentDataData);

  const updateAndShowModal = (
    title,
    description,
    postType,
    attachment,
    showWig
  ) => {
    setModalData({
      title: title,
      description: description,
      postType: postType,
      attachment: attachment,
      showWig: showWig || false,
    });
    setModalOpen(true);
  };

  const scrollToContent = (location, offset) => {
    scroller.scrollTo(location, {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: offset || 50,
    });
  };

  return (
    <Layout>
      <SEO
        navResultData={navResultData}
        contentDataData={contentDataData}
        integratorData={integratorData}
        contentMetaData={contentMetaData}
      />
      <Modal
        classNames={{ modal: 'AccroModal' }}
        open={modalOpen && modalData ? true : false}
        onClose={() => setModalOpen(false)}
        center
      >
        {modalData && (
          <>
            {modalData.showWig === true ? (
              <WigLoader
                weducWebsitesID={pluginOptions.weducWebsitesID}
                weducContainer={pluginOptions.weducContainerID}
                weducEntity={pluginOptions.weducEntityID}
                contentDataData={contentDataData}
                contentMetaData={contentMetaData}
                contentDataArray="0"
                contentDataChild="0"
                customDataArray={modalData.description}
                AccroPressProdEndpoint={pluginOptions.apiEndpoint}
                AccroPressLocalEndpoint={pluginOptions.apiEndpoint}
                integratorID={pluginOptions.integratorID}
                integratorAccessToken={pluginOptions.integratorAccessToken}
                imageFormatting={
                  process.env.IMAGE_FORMATTING_SECRET ||
                  process.env.GATSBY_IMAGE_FORMATTING_SECRET
                }
              />
            ) : (
              <div
                dangerouslySetInnerHTML={{ __html: modalData.description }}
              ></div>
            )}
            {modalData.attachment && modalData.attachment.length > 0 && (
              <ul className="AccroModal_Attachments">
                {modalData.attachment.map((attachment, i) => {
                  return (
                    <li key={i} className="AccroModal_Attachments_Attachement">
                      <a
                        href={attachment.external_url}
                        title={attachment.title}
                        target="_blank"
                        rel="noopener noreferer"
                      >
                        {attachment.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: buildMediaContent(modalData.postType),
              }}
            ></div>
          </>
        )}
      </Modal>

      {contentDataData && contentDataData.length > 0 ? (
        <div className="main-page-wrapper loaded">
          {pluginOptions.useHeroSlider ? (
            <BasicSlider
              OverlayContainer={
                <HeroInnerContent
                  contentMetaData={contentMetaData}
                  contentDataData={contentDataData}
                  noticesMainLoaded={noticesMainLoaded}
                  setNoticesMainLoaded={setNoticesMainLoaded}
                  NoticeTemplate={NoticeTemplate}
                  noticesBannerExpanded={noticesBannerExpanded}
                  toggleNoticesBanner={toggleNoticesBanner}
                  config={config}
                  pluginOptions={pluginOptions}
                  scrollToContent={scrollToContent}
                  videoPlayerModalTriggered={videoPlayerModalTriggered}
                  setVideoPlayerModalTriggered={setVideoPlayerModalTriggered}
                />
              }
              onBeforeChangeFunction={(type, previous, next) => {
                return null;
              }}
              height={
                pluginOptions.heroSliderHeight
                  ? pluginOptions.heroSliderHeight
                  : 'calc(73vh - 50px)'
              }
              shouldDisplayButtons={false}
              onBeforeChangeFunction={(type, next) => {
                return null;
              }}
              onBeforeChangeFunction={(type, next) => {
                return null;
              }}
              slides={contentDataData[0].children[0].children}
            />
          ) : (
            <HeroInnerContent
              contentMetaData={contentMetaData}
              contentDataData={contentDataData}
              noticesMainLoaded={noticesMainLoaded}
              setNoticesMainLoaded={setNoticesMainLoaded}
              NoticeTemplate={NoticeTemplate}
              noticesBannerExpanded={noticesBannerExpanded}
              toggleNoticesBanner={toggleNoticesBanner}
              config={config}
              pluginOptions={pluginOptions}
              scrollToContent={scrollToContent}
              videoPlayerModalTriggered={videoPlayerModalTriggered}
              setVideoPlayerModalTriggered={setVideoPlayerModalTriggered}
            />
          )}
          <div id="scrollHere" className="scrollHere"></div>
          <div className="mainContentWrapper home" id="welcome">
            <section className="app-content">
              <div className="container">
                {pluginOptions.quickLinksType === 'belowHeader' &&
                  contentDataData[0] &&
                  contentDataData[0].children[4] &&
                  contentDataData[0].children[4].children && (
                    <ul className="row blocks">
                      {contentDataData[0].children[4].children.map(
                        (col, index) => {
                          console.log('col', col);

                          return (
                            <li class="col-12 col-3-m block">
                              <div
                                title={col[1].value}
                                class="readMore"
                                onClick={() => {
                                  if (col[2].value === true && col[3].value) {
                                    window.location.href = col[3].value;
                                  } else {
                                    updateAndShowModal(
                                      col[1].value,
                                      col[4].value,
                                      0,
                                      null,
                                      true
                                    );
                                  }
                                }}
                              >
                                <div
                                  title={col[1].value}
                                  class="quickLinkTile"
                                  onClick={() => {
                                    if (col[2].value === true && col[3].value) {
                                      window.location.href = col[3].value;
                                    } else {
                                      updateAndShowModal(
                                        col[1].value,
                                        col[4].value,
                                        0,
                                        null,
                                        true
                                      );
                                    }
                                  }}
                                  style={{
                                    backgroundImage: `url(
                                      ${col[0].value}
                                    )`,
                                  }}
                                >
                                  <div class="wrap">
                                 
                                    <div class="quickLinkText">
                                      <span>{col[1].value}</span>
                                      <div
                                        title={col[1].value}
                                        class="readMore"
                                        onClick={() => {
                                          if (
                                            col[2].value === true &&
                                            col[3].value
                                          ) {
                                            window.location.href = col[3].value;
                                          } else {
                                            updateAndShowModal(
                                              col[1].value,
                                              col[4].value,
                                              0,
                                              null,
                                              true
                                            );
                                          }
                                        }}
                                      >
                                        Find out more
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  )}
                <div className="row homepageWrapper">
                  <ExtraHomepageContentTop contentDataData={contentDataData} />

                  {contentDataData[1] &&
                    contentDataData[1].children &&
                    contentDataData[1].children[0] &&
                    contentDataData[1].children[0].children && (
                      <div
                        className={`homepageWrapper_OpeningContentCards cardLength_${contentDataData[1].children[0].children.length}`}
                      >
                        {contentDataData[1].children[0].children.map(
                          (cards) => {
                            return (
                              <div className="homepageWrapper_OpeningContentCards_Card">
                                {cards[0].value && (
                                  <div className="homepageWrapper_OpeningContentCards_Card_Image">
                                    <img
                                      src={cards[0].value}
                                      alt={cards[0].name}
                                    />
                                  </div>
                                )}
                                {cards[1].value && (
                                  <div className="homepageWrapper_OpeningContentCards_Card_Content">
                                    <div className="homepageWrapper_OpeningContentCards_Card_Content_Inner">
                                      <WigLoader
                                        weducWebsitesID={config.weducWebsitesID}
                                        weducContainer={config.weducContainerID}
                                        weducEntity={config.weducEntityID}
                                        contentDataData={contentDataData}
                                        contentMetaData={contentMetaData}
                                        contentDataArray="1"
                                        contentDataChild="0"
                                        customDataArray={cards[1].value}
                                        AccroPressProdEndpoint={
                                          config.apiEndpoint
                                        }
                                        AccroPressLocalEndpoint={
                                          config.apiEndpoint
                                        }
                                        integratorID={config.integratorID}
                                        integratorAccessToken={
                                          config.integratorAccessToken
                                        }
                                        accroPressSiteID={
                                          config.accroPressSiteID
                                        }
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          }
                        )}
                      </div>
                    )}

                  {contentDataData[2] &&
                    contentDataData[2].children &&
                    contentDataData[2].children[0] &&
                    contentDataData[2].children[0].value && (
                      <div className="mainContent_Wrapper col-12 col-12-m">
                        <WigLoader
                          weducWebsitesID={config.weducWebsitesID}
                          weducContainer={config.weducContainerID}
                          weducEntity={config.weducEntityID}
                          contentDataData={contentDataData}
                          contentMetaData={contentMetaData}
                          contentDataArray="2"
                          contentDataChild="0"
                          AccroPressProdEndpoint={config.apiEndpoint}
                          AccroPressLocalEndpoint={config.apiEndpoint}
                          integratorID={config.integratorID}
                          integratorAccessToken={config.integratorAccessToken}
                          accroPressSiteID={config.accroPressSiteID}
                        />
                      </div>
                    )}

                  {contentDataData[3] &&
                    contentDataData[3].children &&
                    contentDataData[3].children[0] &&
                    contentDataData[3].children[0].children && (
                      <div className="schoolInformation_Wrapper col-12 col-12-m">
                        <div className="schoolInformation_Wrapper_Tabs">
                          {contentDataData[3].children[0].children.map(
                            (school, index) => {
                              return (
                                <div
                                  className={`schoolInformation__Tab schoolInformation__Tab_Active_${
                                    schoolInformationTabActive === index
                                  }`}
                                  onClick={() => {
                                    schoolInformationTabActive !== index
                                      ? setSchoolInformationTabActive(index)
                                      : setSchoolInformationTabActive(null);
                                  }}
                                >
                                  <div className="schoolInformation__Tab_Image">
                                    <img
                                      src={school[1].value}
                                      alt={school[0].value}
                                    />
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                        <div className="schoolInformation_Wrapper_Panels">
                          {contentDataData[3].children[0].children.map(
                            (school, index) => {
                              return (
                                <div
                                  className={`schoolInformation__Panel schoolInformation__Panel_Active_${
                                    schoolInformationTabActive === index
                                  } schoolInformation__School_name_${school[0].value.replace(
                                    ' ',
                                    '_'
                                  )}`}
                                >
                                  <div>
                                    <button
                                      className="close-school-details"
                                      type="submit"
                                      onClick={() => {
                                        schoolInformationTabActive !== index
                                          ? setSchoolInformationTabActive(index)
                                          : setSchoolInformationTabActive(null);
                                      }}
                                    >
                                      <span className="fal fa-times"></span>
                                    </button>
                                    <div className="schoolInformation__Panel_Inner">
                                      <div className="schoolInformation__Panel_Title">
                                        <h2>About {school[0].value}</h2>
                                      </div>
                                      <div className="schoolInformation__Panel_Content_Top">
                                        <div className="schoolInformation__Panel_Content_Top_Left">
                                          <WigLoader
                                            weducWebsitesID={school[8].value}
                                            weducContainer={school[9].value}
                                            weducEntity={school[10].value}
                                            contentDataData={contentDataData}
                                            contentMetaData={contentMetaData}
                                            contentDataArray="3"
                                            contentDataChild="0"
                                            customDataArray={school[11].value}
                                            AccroPressProdEndpoint={
                                              config.apiEndpoint
                                            }
                                            AccroPressLocalEndpoint={
                                              config.apiEndpoint
                                            }
                                            integratorID={config.integratorID}
                                            integratorAccessToken={
                                              config.integratorAccessToken
                                            }
                                            accroPressSiteID={
                                              config.accroPressSiteID
                                            }
                                          />
                                          {school[8].value &&
                                            school[9].value &&
                                            school[10].value &&
                                            school[5].value && (
                                              <div className="weducWidgets_Wrapper col-12 col-12-m">
                                                <div className="row">
                                                  <div className="col-12 col-6-m">
                                                    <div
                                                      className={
                                                        'sidebarMenuItem weducWidgetDisplayWrapper_' +
                                                        newsfeedLoaded
                                                      }
                                                    >
                                                      <div className="header">
                                                        <h3>Latest News</h3>
                                                        <Link
                                                          to={`${school[5].value}/news-and-events/latest-news`}
                                                        >
                                                          View all
                                                        </Link>
                                                      </div>
                                                      <WeducNewsFeed
                                                        loadedEvent={
                                                          setNewsfeedLoaded
                                                        }
                                                        limit={5}
                                                        name={school[8].value}
                                                        container={
                                                          school[9].value
                                                        }
                                                        html={function NewsFeedTemplate(
                                                          props
                                                        ) {
                                                          return (
                                                            <div
                                                              onClick={() =>
                                                                updateAndShowModal(
                                                                  props.data
                                                                    .title,
                                                                  props.data
                                                                    .contentHtml,
                                                                  props.data
                                                                    .postType,
                                                                  props.data
                                                                    .attachment
                                                                )
                                                              }
                                                              className="sidebarDataItem"
                                                            >
                                                              <span className="far fa-newspaper"></span>
                                                              <div>
                                                                <div className="content">
                                                                  <span>
                                                                    {moment(
                                                                      props.data
                                                                        .createdAt
                                                                    ).format(
                                                                      'LL'
                                                                    )}
                                                                  </span>
                                                                  {props.data.contentHtml
                                                                    .replace(
                                                                      /<\/p>]*>?/gm,
                                                                      ' '
                                                                    )
                                                                    .replace(
                                                                      /<[^>]*>?/gm,
                                                                      ''
                                                                    )
                                                                    .substring(
                                                                      0,
                                                                      120
                                                                    )}
                                                                  {props.data.contentHtml
                                                                    .replace(
                                                                      /<\/p>]*>?/gm,
                                                                      ' '
                                                                    )
                                                                    .replace(
                                                                      /<[^>]*>?/gm,
                                                                      ''
                                                                    ).length >
                                                                  120
                                                                    ? '...'
                                                                    : ''}
                                                                </div>
                                                              </div>
                                                            </div>
                                                          );
                                                        }}
                                                        route={`${school[5].value}/news-and-events/latest-news`}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-12 col-6-m">
                                                    <div
                                                      className={
                                                        'sidebarMenuItem weducWidgetDisplayWrapper_' +
                                                        calendarEventsLoaded
                                                      }
                                                    >
                                                      <div className="header">
                                                        <h3>Calendar</h3>
                                                        <Link
                                                          to={`${school[5].value}/news-and-events/events`}
                                                        >
                                                          View all
                                                        </Link>
                                                      </div>
                                                      <WeducDairyDates
                                                        weducAPIURL="https://weduc.accropress.com/smartfeed/"
                                                        limit={5}
                                                        loadedEvent={
                                                          setCalendarEventsLoaded
                                                        }
                                                        name={school[8].value}
                                                        container={
                                                          school[9].value
                                                        }
                                                        html={function CalendarTemplate(
                                                          props
                                                        ) {
                                                          return (
                                                            <div
                                                              onClick={() =>
                                                                updateAndShowModal(
                                                                  props.data
                                                                    .title,
                                                                  props.data
                                                                    .description,
                                                                  props.data
                                                                    .postType,
                                                                  props.data
                                                                    .attachment
                                                                )
                                                              }
                                                              className="sidebarDataItem"
                                                            >
                                                              <span className="far fa-calendar"></span>
                                                              <div>
                                                                {
                                                                  props.data
                                                                    .title
                                                                }
                                                                <span>
                                                                  {moment(
                                                                    props.data
                                                                      .start_date
                                                                  ).format(
                                                                    'LL'
                                                                  )}
                                                                </span>
                                                              </div>
                                                            </div>
                                                          );
                                                        }}
                                                        route={`${school[5].value}/news-and-events/events`}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                        </div>
                                        <div className="schoolInformation__Panel_Content_Top_Right">
                                          {school[2].value && (
                                            <div className="schoolInformation__Panel_Content_Address">
                                              <div>
                                                <strong>
                                                  {school[0].value}
                                                </strong>
                                              </div>
                                              <address>
                                                {school[2].value}
                                              </address>
                                            </div>
                                          )}
                                          {school[3].value && (
                                            <div className="schoolInformation__Panel_Content_Phone">
                                              <div>
                                                <strong>Telephone:</strong>
                                              </div>
                                              <address>
                                                {school[3].value}
                                              </address>
                                            </div>
                                          )}
                                          {school[4].value && (
                                            <div className="schoolInformation__Panel_Content_Email">
                                              <div>
                                                <strong>Email:</strong>
                                              </div>
                                              <address>
                                                {school[4].value}
                                              </address>
                                            </div>
                                          )}
                                          {school[5].value && (
                                            <div className="schoolInformation__Panel_Content_Website">
                                              <a
                                                href={school[5].value}
                                                className="btn btn-primary"
                                              >
                                                Visit this school's site
                                              </a>
                                            </div>
                                          )}
                                          {school[6].value !== undefined &&
                                            school[7].value !== undefined && (
                                              <div className="schoolInformation__Panel_Content_Map">
                                                <MapWidget
                                                  latitude={school[6].value}
                                                  longitude={school[7].value}
                                                />
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    )}

                  {pluginOptions.showMainWeducWidgets === true && (
                    <div className="weducWidgets_Wrapper col-12 col-12-m">
                      <div className="row">
                        <div className="col-12 col-6-m">
                          <div
                            className={
                              'sidebarMenuItem weducWidgetDisplayWrapper_' +
                              newsfeedLoaded
                            }
                          >
                            <div className="header">
                              <h3>Latest News</h3>
                              {pluginOptions.newsPageRoute && (
                                <Link to={pluginOptions.newsPageRoute}>
                                  View all
                                </Link>
                              )}
                            </div>
                            <WeducNewsFeed
                              loadedEvent={setNewsfeedLoaded}
                              limit={5}
                              name={config.weducWebsitesID}
                              container={config.weducContainerID}
                              html={function NewsFeedTemplate(props) {
                                return (
                                  <div
                                    onClick={() =>
                                      updateAndShowModal(
                                        props.data.title,
                                        props.data.contentHtml,
                                        props.data.postType,
                                        props.data.attachment
                                      )
                                    }
                                    className="sidebarDataItem"
                                  >
                                    <span className="far fa-newspaper"></span>
                                    <div>
                                      <div className="content">
                                        <span>
                                          {moment(props.data.createdAt).format(
                                            'LL'
                                          )}
                                        </span>
                                        {props.data.contentHtml
                                          .replace(/<\/p>]*>?/gm, ' ')
                                          .replace(/<[^>]*>?/gm, '')
                                          .substring(0, 120)}
                                        {props.data.contentHtml
                                          .replace(/<\/p>]*>?/gm, ' ')
                                          .replace(/<[^>]*>?/gm, '').length >
                                        120
                                          ? '...'
                                          : ''}
                                      </div>
                                    </div>
                                  </div>
                                );
                              }}
                              route={
                                pluginOptions.newsPageRoute
                                  ? pluginOptions.newsPageRoute
                                  : '/news-and-events/latest-news'
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 col-6-m">
                          <div
                            className={
                              'sidebarMenuItem weducWidgetDisplayWrapper_' +
                              calendarEventsLoaded
                            }
                          >
                            <div className="header">
                              <h3>Calendar</h3>
                              {pluginOptions.eventsPageRoute && (
                                <Link to={pluginOptions.eventsPageRoute}>
                                  View all
                                </Link>
                              )}
                            </div>
                            <WeducDairyDates
                              weducAPIURL="https://weduc.accropress.com/smartfeed/"
                              limit={5}
                              loadedEvent={setCalendarEventsLoaded}
                              name={config.weducWebsitesID}
                              container={config.weducContainerID}
                              html={function CalendarTemplate(props) {
                                return (
                                  <div
                                    onClick={() =>
                                      updateAndShowModal(
                                        props.data.title,
                                        props.data.description,
                                        props.data.postType,
                                        props.data.attachment
                                      )
                                    }
                                    className="sidebarDataItem"
                                  >
                                    <span className="far fa-calendar"></span>
                                    <div>
                                      {props.data.title}
                                      <span>
                                        {moment(props.data.start_date).format(
                                          'LL'
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                );
                              }}
                              route={
                                pluginOptions.eventsPageRoute
                                  ? pluginOptions.eventsPageRoute
                                  : '/news-and-events/events'
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <ExtraHomepageContentBottom
                    contentDataData={contentDataData}
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      ) : (
        <div className="main-page-wrapper loading"></div>
      )}
    </Layout>
  );
}
